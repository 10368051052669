import React from 'react';
import { COLOR } from '@latitude/core/utils/constants';
import RatesAndFeesComponent from '@latitude/rates-and-fees';
import { Link } from '@latitude/link';

const RatesAndFees = () => (
  <div id="rates-and-fees">
    <RatesAndFeesComponent
      id="rates"
      css={`
        button.link-block {
          background-color: ${COLOR.GREY5};
          cursor: pointer;
        }
        .accordion-custom .accordion__row {
          padding: 5px 0;
          margin: 0 30px;
        }
      `}
      title="Rates & Fees"
      ratesListProps={{
        data: [
          {
            largeText: '0',
            showExtras: true,
            smallText: 'on long-term Interest Free Payment Plans'
          },
          {
            largeText: '0',
            showExtras: true,
            smallText:
              'up to 55 days interest free<sup>1</sup> on everyday purchases'
          },
          {
            largeText: '27.49',
            showExtras: true,
            smallText: 'interest rate on everyday purchases'
          },
          {
            largeText: '$0',
            smallText: 'account establishment fee'
          },
          {
            largeText: '$10.95',
            smallText: 'monthly account service fee (subject to change)'
          }
        ]
      }}
      accordionProps={{
        items: [
          {
            id: 'other-fees',
            title: 'Other rates & fees',
            content: (
              <div className="accordion-custom">
                <div className="d-lg-flex accordion__row">
                  <p className="w-100 pr-3">
                    <strong>
                      Expired long-term Interest Free Payment Plans
                    </strong>
                  </p>
                  <p className="w-100">29.99% p.a.</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100 pr-3">
                    <strong>Interest rate on cash advances</strong>
                  </p>
                  <p className="w-100">29.99% p.a.</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Cash advance fee</strong>
                  </p>
                  <p className="w-100">The greater of 3.5% or $4.</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100 pr-3">
                    <strong>Interest rate on balance transfers</strong>
                  </p>
                  <p className="w-100">
                    Please refer to the Balance Transfer interest rate, term and
                    balance transfer fee set out in any offer presented to you.
                  </p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Payment handling fees</strong>
                  </p>

                  <ul css="width:100%; margin-top:0; padding-left: 17px;">
                    <li>
                      $0 when you pay via the Latitude App, Online Service
                      Centre, mail, or direct debit
                    </li>
                    <li>
                      $1.95 (online using BPAY<sup>®</sup>)
                    </li>
                    <li>$3.95 (in person at Australia Post)</li>
                  </ul>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Replacement card fee</strong>
                  </p>
                  <p className="w-100">$0</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Late fee</strong>
                  </p>
                  <p className="w-100">$45</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Online statements</strong>
                  </p>
                  <p className="w-100">
                    $0 by opting to receive statements online. Simply login to
                    the Online Service Centre.
                  </p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Paper statements</strong>
                  </p>
                  <p className="w-100">
                    $5.00 <br />
                    You can avoid this fee by opting to receive statements
                    online. Simply login to the Online Service Centre.
                  </p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100 pr-3">
                    <strong>Balance Transfer fee</strong>
                  </p>
                  <p className="w-100">Up to 3% of balance transfer amount</p>
                </div>
              </div>
            )
          },

          {
            id: 'international-fees',
            title: 'International fees',
            content: (
              <div className="accordion-custom">
                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>International transaction fee</strong>
                  </p>
                  <p className="w-100">3% on all purchases and cash advances</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>International cash advance fee</strong>
                  </p>
                  <p className="w-100">$4, on top of the cash advance fee</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>International cash over the counter fee</strong>
                  </p>
                  <p className="w-100">$5, on top of the cash advance fee</p>
                </div>
              </div>
            )
          },

          {
            id: 'other-information',
            title: 'Other information',
            content: (
              <div className="accordion-custom">
                <div className="d-lg-flex accordion__row">
                  <p className="w-100 col-sm-9">
                    Before making a decision, please read these documents to
                    ensure you&apos;re aware of all fees, charges and interest
                    rates.
                  </p>
                  <p className="w-100 col-sm-3">
                    <Link href="https://assets.latitudefinancial.com/legals/key-fact-sheet/gomc/kfs.html">
                      Key facts sheet
                    </Link>
                    <br />
                    <Link href="https://assets.latitudefinancial.com/legals/conditions-of-use/gomc-au/cou.pdf">
                      Conditions of use
                    </Link>
                  </p>
                </div>
              </div>
            )
          }
        ]
      }}
    />
  </div>
);

export default RatesAndFees;
